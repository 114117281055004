.button-on-the-left {
  position: absolute;
  top: 2%;
  right: 50%;
  z-index: 2;
}

/* left: 50vw; 50vm */

.button-on-the-right {
  position: absolute;
  top: 92%;
  right: 50%;
  z-index: 2;
}

.logo-on-bottom-right {
  position: absolute !important;
  bottom: 10px;
  right: 1vw;
  z-index: 2;
}

.button-close-on-the-right {
  position: absolute;
  top: 3%;
  right: 2vw;
  z-index: 2;
}

.button-menu-on-the-left {
  position: absolute !important;
  top: 10px;
  left: 11vw;
  z-index: 2;
}

.text-on-the-right {
  position: absolute !important;
  top: 10px;
  left: 26rem;
}

.google-button-on-center {
  position: absolute;
  top: 50%;
  left: 45vw;
  padding: 0 45px !important;
  border-radius: 35px !important;
}

.hero-image {
  width: 100%;
  height: 100vh;
}
